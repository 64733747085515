.grid-2-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.grid-3-col {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-auto-rows: auto;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
}

.grid-4-col {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: auto;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
}

.grid-5-col {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-auto-rows: auto;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
}

.grid-infinite-col {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);
    grid-template-rows: auto;
    grid-gap: 1.5rem;
}

.grid-6-col {
    display: grid;
    grid-template-columns: 1.75fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 1.5rem;
}

.grid-3-col-cta {
    position: relative;
    display: grid;
    grid-template-columns: 3fr 1fr 3fr;
    grid-auto-rows: auto;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
}

.grid-rows {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
}

.grid-big-gap {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
}

.pricing-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    padding: 0.4rem 2rem;
}

.footer-main-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.pricing-row:first-child {
    padding-top: 1.5rem;
}

.pricing-row:last-child {
    padding-bottom: 1rem;
}

.pricing-row *:last-child{
    text-align: center;
}

.no-gap {
    grid-column-gap: 0rem;
    grid-row-gap: 0rem;
}

.md-gap {
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
}

.lg-gap {
    grid-column-gap: 6rem;
    grid-row-gap: 6rem;
}

.line-gap {
    background-color: var(--lightish);
    grid-gap: 1px;
}

.col-span-2 {
    grid-column: 2 / 4;
}


@media screen and (max-width: 1200px) {
    .grid-4-col {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .grid-5-col {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    .grid-infinite-col {
        grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);
    }
}

@media screen and (max-width: 991px) {
    .grid-2-col {
        grid-template-columns: auto;
    }
    .grid-3-col {
        grid-template-columns: 1fr 1fr;
    }
    .grid-4-col {
        grid-template-columns: auto;
    }
    .grid-5-col {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .grid-infinite-col {
        grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
    }

    .footer-main-grid {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 1.5rem;
    }

    .grid-3-col-cta {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
    }

}


@media screen and (max-width: 767px) {
    .col-span-2 {
        grid-column: auto;
    }
    
    .grid-2-col {
        grid-template-columns: 1fr;
    }
    .grid-3-col {
        grid-template-columns: 1fr;
    }
}
    
@media screen and (max-width: 576px) {
    .footer-main-grid {
        grid-template-columns: 1fr;
    }
    .grid-5-col {
        grid-template-columns: 1fr;
    }
}