@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-Light.woff2') format('woff2'),
        url('OpenSauceOne-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-Italic.woff2') format('woff2'),
        url('OpenSauceOne-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-LightItalic.woff2') format('woff2'),
        url('OpenSauceOne-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-Light.woff2') format('woff2'),
        url('OpenSauceOne-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-Italic.woff2') format('woff2'),
        url('OpenSauceOne-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-Medium.woff2') format('woff2'),
        url('OpenSauceOne-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-Regular.woff2') format('woff2'),
        url('OpenSauceOne-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-MediumItalic.woff2') format('woff2'),
        url('OpenSauceOne-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-MediumItalic.woff2') format('woff2'),
        url('OpenSauceOne-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-LightItalic.woff2') format('woff2'),
        url('OpenSauceOne-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-Regular.woff2') format('woff2'),
        url('OpenSauceOne-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-SemiBold.woff2') format('woff2'),
        url('OpenSauceOne-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-SemiBold.woff2') format('woff2'),
        url('OpenSauceOne-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-SemiBoldItalic.woff2') format('woff2'),
        url('OpenSauceOne-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-ExtraBold.woff2') format('woff2'),
        url('OpenSauceOne-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-Bold.woff2') format('woff2'),
        url('OpenSauceOne-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-BoldItalic.woff2') format('woff2'),
        url('OpenSauceOne-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-BoldItalic.woff2') format('woff2'),
        url('OpenSauceOne-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-ExtraBold.woff2') format('woff2'),
        url('OpenSauceOne-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-SemiBoldItalic.woff2') format('woff2'),
        url('OpenSauceOne-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-BlackItalic.woff2') format('woff2'),
        url('OpenSauceOne-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-BlackItalic.woff2') format('woff2'),
        url('OpenSauceOne-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-Medium.woff2') format('woff2'),
        url('OpenSauceOne-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-ExtraBoldItalic.woff2') format('woff2'),
        url('OpenSauceOne-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-ExtraBoldItalic.woff2') format('woff2'),
        url('OpenSauceOne-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-Bold.woff2') format('woff2'),
        url('OpenSauceOne-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-Black.woff2') format('woff2'),
        url('OpenSauceOne-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('OpenSauceOne-Black.woff2') format('woff2'),
        url('OpenSauceOne-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

